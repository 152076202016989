import * as ct from 'countries-and-timezones'
export const useCountry = (): string => {
    const country = useRequestHeaders(['X-Region'])
    if (country['x-region']) return country['x-region'].toUpperCase()

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const region = ct.getCountryForTimezone(timezone)
    if (region) {
        return region.id
    } else {
        return 'US'
    }
}